import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Card,
  Checkbox,
  CardHeader,
  Button
} from '@mui/material';
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import Requests from '../../../BackofficeRequests/Requests';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/* import { mockData } from './mockedServices'; */
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../../context/alert';

function ChannelServices({ channel, submit }) {
  const [items, setItems] = useState({});
  const { t, i18n } = useTranslation();

  const { showError, showMessage } = useAlerts();

  const onDrop = (type, { removedIndex, addedIndex }) => {
    setItems(prevItems => {
      const newItems = { ...prevItems };
      newItems[type] = arrayMove(newItems[type], removedIndex, addedIndex);
      return newItems;
    });
  };

  const handleConfigChange = (type, index, config, value) => {
    setItems(prevItems => {
      const newItems = { ...prevItems };
      newItems[type][index].configValues[config] = value;
      return newItems;
    });
  };

  const handleToggleActive = (type, index) => {
    setItems(prevItems => {
      const newItems = { ...prevItems };
      newItems[type][index].isActive = !newItems[type][index].isActive;
      return newItems;
    });
  };

  useEffect(async () => {
    let adv = [];
    let groupedItems = null;

    console.log("ADV", adv)
    if (channel.advConfig !== "" && channel.advConfig !== undefined && channel.advConfig !== null) {
      groupedItems = JSON.parse(channel.advConfig);
    } else {
      adv = await Requests.instance.adv.getList();
      adv = adv.data.docs;
      adv.forEach((_adv, index) => {
        adv[index].config = JSON.parse(_adv.config);
        delete adv[index].organizationId
      })
      // load from service
      groupedItems = adv.reduce((acc, data) => {
        const key = data.type;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push({
          ...data,
          id: `${key}-${acc[key].length + 1}`,
          configValues: { ...data.config }
        });
        return acc;
      }, {});
    }

    console.log("All service for organization", groupedItems);
    console.log("All service configured for channel", channel.advConfig);
    setItems(groupedItems);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {Object.keys(items).map(type => (
          <Grid item xs={12} key={type}>
            <Accordion sx={{ backgroundColor: "#161C24" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${type}-content`}
                id={`panel-${type}-header`}
              >
                <Typography variant='h5' sx={{ flexGrow: 1 }}>{type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop.bind(null, type)}>
                    {items[type].map((item, index) => (
                      <Draggable key={`${item.id}-${index}`}>
                        <Card sx={{ backgroundColor: "#212B36", margin: 1 }}>
                          <CardHeader
                            title={`${item.name}`}
                          >
                          </CardHeader>
                          <ListItem>
                            <Grid container alignItems="center" justifyContent={"end"}>
                              <Grid item md={11} >
                                <Box display="flex" alignItems="center">
                                  <Typography variant="body2">{t('Servizio Attivo')}</Typography>
                                  <Checkbox
                                    checked={item.isActive}
                                    onChange={() => handleToggleActive(type, index)}
                                  />
                                </Box>
                              </Grid>
                              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <ListItemText primary={`Priorità ${index + 1}`} />
                              </Grid>
                              <Grid item xs={11} marginTop={2}>
                                <Grid container spacing={1} style={{ minHeight: '60px' }}>
                                  {Object.keys(item.configValues).map((config, configIndex) => (
                                    <Grid item xs={12} md={12} key={`${item.id}-${configIndex}`}>
                                      <TextField
                                        sx={{ margin: 0.5 }}
                                        label={config}
                                        size='small'
                                        value={item.configValues[config]}
                                        onChange={(e) => handleConfigChange(type, index, config, e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid item xs={1} style={{ minHeight: '60px' }}>
                                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                  <ListItemSecondaryAction>
                                    <ListItemIcon className="drag-handle">
                                      <DragHandleIcon />
                                    </ListItemIcon>
                                  </ListItemSecondaryAction>
                                </Box>
                              </Grid>

                            </Grid>
                          </ListItem>
                        </Card>
                      </Draggable>
                    ))}
                  </Container>
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid >
        ))
        }
        <></>
      </Grid >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }} >
        <Button
          onClick={() => {
            console.log("SAVE ADV", items);
            channel.advConfig = JSON.stringify(items);
            submit()
          }}
          type="submit"
          color="primary"
          size="large"
        >
          {t('channel.confirm')}
        </Button>
      </Box>

    </>
  );
}

export default ChannelServices;
